import Swiper, {Navigation, Pagination, Autoplay} from "swiper";

const swiper = new Swiper(".main-catalog__swiper", {
	modules: [Navigation, Pagination, Autoplay],
	loop: true,
	autoplay: {
		delay: 10000,
		disableOnInteraction: false,
		pauseOnMouseEnter: false
	},
	pagination: {
		el: '.main-catalog__swiper .swiper-pagination',
		clickable: true,
	}
});

