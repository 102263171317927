import Swiper, {Autoplay, Navigation, Pagination} from "swiper";

const swiper = new Swiper('.catalog-menu__swiper', {
	modules: [Navigation, Pagination, Autoplay],
	slidesPerView: 4,
	spaceBetween: 0,
	centerInsufficientSlides: true,
	loop: false,
	navigation: {
		nextEl: ".catalog-menu__swiper-button-next",
		prevEl: ".catalog-menu__swiper-button-prev",
	},
	breakpoints: {
		320: {
			slidesPerView: 4
		},

		768: {
			slidesPerView:  8
		},
		992: {
			slidesPerView: 11
		}
	}
});
