document.querySelectorAll('.personal-data__icon').forEach((button) => {
	button.addEventListener('click', (event) => {
		let el = event.currentTarget;
		let inp = el.closest('.personal-data__form-group').querySelector('input');
		el.classList.toggle("is-active");
		if (el.classList.contains('is-active')) {
			inp.setAttribute('type', 'text');
		} else {
			inp.setAttribute('type', 'password');
		}
	});
});
