import Swiper, {Navigation, FreeMode, Thumbs} from "swiper";

const swiper = new Swiper(".product-detail__thumb-swiper", {
	modules: [Navigation, FreeMode, Thumbs],
	watchSlidesProgress: true,
	freeMode: true,
	slidesPerView: 3
});
const swiper2 = new Swiper(" .product-detail__media-swiper", {
	modules: [Navigation, FreeMode, Thumbs],
	navigation: {
		nextEl: ".product-detail__thumb-swiper-button-next",
		prevEl: ".product-detail__thumb-swiper-button-prev",
	},
	thumbs: {
		swiper: swiper,
	},
});
