import jQuery from "jquery";

window.$ = window.jQuery = require('jquery');
// Fancybox
const fancybox = require('@fancyapps/fancybox');
import Swiper, {Navigation, Pagination, Autoplay} from 'swiper';
import '../../components/catalog-menu/catalog-menu'
import '../../components/product-detail/product-detail'
import '../../components/personal-data/personal-data'
import '../../components/main-catalog/main-catalog'
(function ($) {
	$('.header__burger').click(function (e) {
		e.preventDefault();
		$('html').toggleClass('menu-open')
		$('html').removeClass('main-summenu-open')
	});
	$('.has-submenu.main-menu__link').click(function (e) {
		e.preventDefault();
		$('html').toggleClass('main-summenu-open')
	});
	$('.header_transparent .has-submenu-item > a, .header_transparent .main-menu__submenu').hover(
		function () { //over
			$(this).closest('.header').removeClass('header_transparent')
		},
		function () { //out
			$(this).closest('.header').addClass('header_transparent')
		}
	);

	$('.header_has-bg .has-submenu-item > a, .header_has-bg .main-menu__submenu').hover(
		function () { //over
			$(this).closest('.header').removeClass('header_has-bg')
		},
		function () { //out
			$(this).closest('.header').addClass('header_has-bg')
		}
	);
	$('.has-submenu.main-menu__link').mouseenter(
		function () {
			$(this).closest('.has-submenu-item').addClass('is-sumbmenu-open').siblings().removeClass('is-sumbmenu-open')
		});
	$('.has-submenu-item, .main-menu__list').mouseleave(
		function () {
			$(this).removeClass('is-sumbmenu-open');
		});

	$('.main-menu__back').click(function (e) {
		e.preventDefault();
		$('html').removeClass('main-summenu-open')
	});
	$('.search-box__btn').click(function (e) {
		e.preventDefault();
		$(this).closest('.search-box').toggleClass('is-open')
		$('html').removeClass('menu-open')
		$('html').removeClass('main-summenu-open')
	});
	$('.search-box__close').click(function (e) {
		e.preventDefault();
		$(this).closest('.search-box').find('input').val('')
		$(this).closest('.search-box').removeClass('is-open')
	});
	$('[data-fancybox]').fancybox();
	$('.btn-up').bind('click', function (event) {
		event.preventDefault();
		$('html, body').stop().animate({
			scrollTop: 0
		}, 1200);

	});
	$('[data-fancybox-reg]').click(function (e) {
		e.preventDefault();
		let popup = $(this).attr('href');
		$.fancybox.close();
		$.fancybox.open({
			src: popup,
			type: 'inline'
		});
	})
	const swiper = new Swiper('.main-banner__swiper', {
		modules: [Navigation, Pagination, Autoplay],
		loop: true,
		autoplay: {
			delay: 10000,
			disableOnInteraction: false,
			pauseOnMouseEnter: false
		},
		pagination: {
			el: '.main-banner__swiper .swiper-pagination',
			clickable: true,
		}
	});

	$('.cookies-box__close').click(function (e) {
		e.preventDefault();
		$(this).closest('.cookies-box').stop().slideUp()
	})
	$('.contacts-form__select-head').click(function (e) {
		e.preventDefault();
		let $select = $(this).closest('.contacts-form__select');
		let $dropdown = $(this).closest('.contacts-form__select').find('.contacts-form__select-dropdown');
		$dropdown.stop().slideToggle()
		$select.stop().toggleClass('is-open')
	})
	$('.contacts-form__select li span').click(function (e) {
		e.preventDefault();
		let $select = $(this).closest('.contacts-form__select');
		let $dropdown = $(this).closest('.contacts-form__select').find('.contacts-form__select-dropdown');
		let $input = $(this).closest('.contacts-form__select').find('input');
		let $head = $(this).closest('.contacts-form__select').find('.contacts-form__select-head');
		$dropdown.find('li').removeClass('is-active');
		$(this).closest('li').addClass('is-active')
		$input.val($(this).text())
		$head.text($(this).text())
		$dropdown.stop().slideUp()
		$select.stop().removeClass('is-open')
	});

	$(document).click(function (event) {
		if ($(event.target).closest('.contacts-form__select').length)
			return;
		$('.contacts-form__select').removeClass('is-open');
		$('.contacts-form__select.contacts-form__select-dropdown').stop().slideUp();
		event.stopPropagation();
	});

	$('.contacts-form__file input').change(function (e) {
		if ($(this)[0].files[0] && $(this)[0].files[0].name) {
			$(this).closest('.contacts-form__file').addClass('is-active').find('.contacts-form__file-txt').text($(this)[0].files[0].name)
		}
	})

	$(window).on("load", function () {
		let windowHeight = $(window).outerHeight();
		if ($('.main-banner').length) {
			$('.main-banner__swiper-slide').css({'min-height': windowHeight + 'px'})
		}
	});
})(jQuery);


